:root,
:host {
  --_wrapper-max-width: 31.75rem;
  --wrapper-padding-inline: var(--size-400);
  --wrapper-max-width: calc(
    var(--_wrapper-max-width) + 2 * var(--wrapper-padding-inline)
  );

  @media (--sm-n-above) {
    --_wrapper-max-width: 43rem;
  }

  @media (--md-n-above) {
    --_wrapper-max-width: 58rem;
  }

  @media (--lg-n-above) {
    --_wrapper-max-width: 73rem;
  }
}
