:root,
:host {
  --animation-scale-down: scale-down 0.5s var(--ease-3);
  --animation-slide-out-down: slide-out-down 0.5s var(--ease-3);
  --animation-slide-in-up: slide-in-up 0.5s var(--ease-3);
  --animation-slide-in-from-left: slide-in-from-left 0.5s var(--ease-3);
  --animation-slide-out-to-left: slide-out-to-left 0.5s var(--ease-3);
  --animation-spin: spin 2s linear infinite;
  --animation-paw-loader: fade-out-at-50 2050ms infinite both ease-in-out;
}

@keyframes fade-out-at-50 {
  0% {
    opacity: 1;
  }

  50%,
  100% {
    opacity: 0;
  }
}

@keyframes scale-down {
  to {
    transform: scale(0.75);
  }
}

@keyframes slide-out-down {
  to {
    transform: translateY(100%);
  }
}

@keyframes slide-in-up {
  from {
    transform: translateY(100%);
  }
}

@keyframes slide-in-from-left {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0%);
  }
}

@keyframes slide-out-to-left {
  from {
    transform: translateX(0%);
  }

  to {
    transform: translateX(-100%);
  }
}

@keyframes spin {
  to {
    transform: rotate(1turn);
  }
}
