:root,
:host {
  --c-neutral-100: #fff;
  --c-neutral-200: #f3f3f3;
  --c-neutral-300: #d8d8d8;
  --c-neutral-500: #767676;
  --c-neutral-900: #222;
  --c-primary-300: #009cde;
  --c-primary-400: #005a9e;
  --c-primary-600: #00427a;
  --c-primary-800: #01325b;
  --c-error-400: #e30613;
  --c-error-600: #ae050f;
  --c-error-800: #720000;
  --c-warning-400: #f8a91e;
  --c-success-400: #659c41;
  --c-info-400: var(--c-primary-400);
  --c-surface-100: var(--c-neutral-100);
  --c-surface-200: #f0f9fd;
  --c-surface-225: #ebf4f9;
  --c-surface-250: #e5f5fb;
  --c-surface-275: #dbf1fa;
  --c-surface-300: #cbd6e2;
  --c-surface-400: var(--c-primary-400);
  --c-surface-600: var(--c-primary-600);
  --c-cta-400: var(--c-error-400);
  --c-cta-600: var(--c-error-600);
  --c-cta-800: var(--c-error-800);
  --focus-color: var(--c-primary-800);
}
