:root,
:host {
  /* Base font sizes. Prefer using the scaling font sizes instead. */
  --fs-300: 0.875rem; /* 14px */
  --fs-400: 1rem; /* 16px */
  --fs-450: 1.125rem; /* 18px */
  --fs-500: 1.25rem; /* 20px */
  --fs-600: 1.5rem; /* 24px */
  --fs-700: 1.75rem; /* 28px */
  --fs-800: 2rem; /* 32px */
  --fs-825: 2.25rem; /* 36px */
  --fs-850: 2.5rem; /* 40px */
  --fs-900: 3rem; /* 48px */
  --fs-1000: 3.75rem; /* 60px */

  /* Scaling font sizes */
  --heading-xl: clamp(var(--fs-900), 6vw, var(--fs-1000)); /* 48px -> 60px */
  --heading-lg: clamp(var(--fs-700), 4vw, var(--fs-850)); /* 28px -> 40px */
  --heading-md: clamp(var(--fs-600), 4vw, var(--fs-700)); /* 24px -> 28px */
  --heading-sm: clamp(var(--fs-500), 2vw, var(--fs-600)); /* 20px -> 24px */
  --label-font-size: var(--fs-450);
  --button-font-size: var(--fs-450);
  --text-lg: clamp(var(--fs-450), 2vw + 0.5rem, var(--fs-500));
  --text-md: clamp(var(--fs-400), 1.5vw + 0.5rem, var(--fs-450));
  --text-sm: var(--fs-300);
  --text-xs: 0.8125rem;
  --text-size: var(--text-md);

  @media (--sm-n-above) {
    --text-xs: var(--text-sm);
  }
}
